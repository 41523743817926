export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [3],
		"/about": [~8],
		"/admin": [~9],
		"/login": [~10],
		"/policy": [11],
		"/(protected)/profile": [~4,[2]],
		"/(protected)/recorder": [5,[2]],
		"/(protected)/session": [~6,[2]],
		"/(protected)/session/[sessionId]": [~7,[2]],
		"/signup": [~12]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';